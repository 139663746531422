var GetAugnitoClient = function (appLogic) {
    
    appLogic.Server= "wss://apis.augnito.ai/speechapi";    
    appLogic.AccountCode= "8981b3fd-bd19-4606-8866-f31b3d277970";
    appLogic.AccessKey= "d8e627c9f49a4a9ea2a1e08e2764528c";
    appLogic.ContentType= "audio/x-raw,+layout=(string)interleaved,+rate=(int)16000,+format=(string)S16LE,+channels=(int)1";
    appLogic.NoiseCt= "-1";
    appLogic.LmId= 101; /*Change LmId*/
    appLogic.UserTag="userInfo"; /*user name or user unique information*/ 
    appLogic.LoginToken="userLoginToken"; /*Login token or unique login id*/
    appLogic.OtherInfo="otherinfo";
    appLogic.SourceApp="SDKdemo";      
    return new AugnitoSDK(appLogic);    
}
this.GetAugnitoClient = GetAugnitoClient;
